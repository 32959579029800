import { Select, Tag } from "antd";
import { useShowInputContext } from "context/ShowInputContext";
import { isEqual } from "lodash";
import { useInstallationMultiStatusUpdate } from "queryHook/installation";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InstallationSchema, MultiStatus } from "types/installation";
import {
  CLIENT_ORDER_EMAIL_ADDRESS,
  MULTI_STATUS,
} from "utils/global/constants";
import { getIsWithPermission } from "utils/global/installationHelper";

const { Option } = Select;

type CustomTagProps = {
  label: React.ReactNode;
};

const tagRender = (props: CustomTagProps) => {
  const { label } = props;

  return <Tag style={{ marginRight: 3 }}>{label}</Tag>;
};

const generateStatusArray = (installation: InstallationSchema) => {
  const withPermission = getIsWithPermission(installation);

  let status: MultiStatus[] = MULTI_STATUS.filter((key) => installation[key]);

  if (withPermission) {
    // When the client orders with `isWithPermission` remove status `approvalSubmittedByCustomer`
    status = status.filter(
      (v) => !STATUS_REMOVED_WHEN_WITH_PERMISSION_IS_TRUE.includes(v)
    );
  } else {
    // When the client orders without `isWithPermission` remove status `requestApproval` and `permitGranted`
    status = status.filter(
      (v) => !STATUS_REMOVED_WHEN_WITH_PERMISSION_IS_FALSE.includes(v)
    );
  }

  // When the client orders with email `info@elias-umzuege.de` hide status `sendInvoice` and `invoicePaid`
  if (installation.customer.email === CLIENT_ORDER_EMAIL_ADDRESS) {
    status = status.filter(
      (v) => !STATUS_REMOVED_WHEN_ORDER_IS_MADE_BY_CLIENT.includes(v)
    );
  }

  // Installation with `noSignInstallation` means `erectSigns` and `collectSigns` are  irrelevant
  if (installation.noSignInstallation) {
    status = status.filter(
      (v) => !STATUS_REMOVED_WHEN_ORDER_IS_NOT_SIGN_INSTALLATION.includes(v)
    );
  }

  return status;
};

const STATUS_REMOVED_WHEN_WITH_PERMISSION_IS_TRUE: MultiStatus[] = [
  "approvalSubmittedByCustomer",
];

const STATUS_REMOVED_WHEN_WITH_PERMISSION_IS_FALSE: MultiStatus[] = [
  "requestApproval",
  "permitGranted",
];

const STATUS_REMOVED_WHEN_ORDER_IS_MADE_BY_CLIENT: MultiStatus[] = [
  "sendInvoice",
  "invoicePaid",
];

const STATUS_REMOVED_WHEN_ORDER_IS_NOT_SIGN_INSTALLATION: MultiStatus[] = [
  "erectSigns",
  "collectSigns",
];

const getStatusOptions = (installation: InstallationSchema) => {
  const withPermission = getIsWithPermission(installation);

  let filteredStatus: MultiStatus[] = [...MULTI_STATUS];

  if (withPermission) {
    // When the client orders with `isWithPermission` hide status `approvalSubmittedByCustomer`
    filteredStatus = filteredStatus.filter(
      (v) => !STATUS_REMOVED_WHEN_WITH_PERMISSION_IS_TRUE.includes(v)
    );
  } else {
    // When the client orders without `isWithPermission` hide status `requestApproval` and `permitGranted`
    filteredStatus = filteredStatus.filter(
      (v) => !STATUS_REMOVED_WHEN_WITH_PERMISSION_IS_FALSE.includes(v)
    );
  }

  // When the client orders with email `info@elias-umzuege.de` hide status `sendInvoice` and `invoicePaid`
  if (installation.customer.email === CLIENT_ORDER_EMAIL_ADDRESS) {
    filteredStatus = filteredStatus.filter(
      (v) => !STATUS_REMOVED_WHEN_ORDER_IS_MADE_BY_CLIENT.includes(v)
    );
  }

  // Installation with `noSignInstallation` means `erectSigns` and `collectSigns` are  irrelevant
  if (installation.noSignInstallation) {
    filteredStatus = filteredStatus.filter(
      (v) => !STATUS_REMOVED_WHEN_ORDER_IS_NOT_SIGN_INSTALLATION.includes(v)
    );
  }

  return filteredStatus;
};

export default function StatusSelect({
  width = "100%",
  installation,
}: {
  width?: number | string;
  installation: InstallationSchema;
}) {
  const { t } = useTranslation();
  const { mutate, isLoading: statusChangeLoading } =
    useInstallationMultiStatusUpdate(installation.id);

  const { isLoading } = useShowInputContext();

  // Keeps record of all the statuses that are already checked
  const [multiValue, setMultiValue] = useState(
    generateStatusArray(installation)
  );

  useEffect(() => {
    setMultiValue(generateStatusArray(installation));
  }, [installation]);

  const handleStatusChange = (statuses: MultiStatus[]) => {
    setMultiValue(statuses);
  };

  const onBlur = () => {
    const isStatusSame = isEqual(generateStatusArray(installation), multiValue);

    if (isStatusSame) {
      return;
    }

    const data = {
      acceptOrder: false,
      rejectOrder: false,
      requestApproval: false,
      permitGranted: false,
      approvalSubmittedByCustomer: false,
      erectSigns: false,
      installationProtocol: false,
      sendDocumentsToCustomer: false,
      collectSigns: false,
      sendInvoice: false,
      invoicePaid: false,
      closeOrder: false,
    };

    for (const status of multiValue) {
      data[status] = true;
    }

    mutate(data);
  };

  return (
    <div>
      <Select
        style={{ width }}
        className="multi-status-select"
        value={multiValue}
        placeholder={t("orders:status")}
        onChange={handleStatusChange}
        mode="multiple"
        tagRender={tagRender}
        onBlur={onBlur}
        showSearch={false}
        disabled={isLoading || statusChangeLoading}
        listHeight={1000} // list should be full height
      >
        {getStatusOptions(installation).map((status) => {
          return (
            <Option key={status} value={status}>
              {t(`orders:multiStatus:${status}`)}
              {installation.noSignInstallation &&
                status === "requestApproval" &&
                ` (${t("orders:withoutSigns")})`}
            </Option>
          );
        })}
      </Select>
    </div>
  );
}
