import type { ProColumns } from "@ant-design/pro-table";
import { ColumnsStateType } from "@ant-design/pro-table/lib/typing";
import { Button, Checkbox, message, Spin } from "antd";
import AntdTablePro from "components/antdTablePro";
import ShowInputProvider from "context/ShowInputContext";
import { debounce } from "lodash";
import AssignWorkerSelect from "pages/orders/AssignWorkerSelect";
import StatusSelect from "pages/orders/StatusSelect";
import ViewComponent from "pages/orders/View";
import WorkerOrderNumber from "pages/orders/WorkerOrderNumber";
import {
  useInstallationList,
  useInstallationUpdate,
} from "queryHook/installation";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SortingOrder } from "types";
import { InstallationSchema, MultiStatus } from "types/installation";
import { getGoogleMapLink } from "utils/global";
import { getIsWithPermission } from "utils/global/installationHelper";
import InvoiceStatusToggle from "./InvoiceStatusToggle";

export type StatusFilter = Partial<Record<MultiStatus, boolean>>;

type OrderByOptions =
  | "createdAt"
  | "pickupDate"
  | "setupDate"
  | "updatedDateRangeFrom";

type FilterParams = {
  page: number;
  size: number;
  search: string;
  order: SortingOrder;
  orderBy: OrderByOptions;
  includeEmail?: string;
  excludeEmail?: string;
  hasAll?: boolean;
  withPermission?: boolean;
  isAccountingTable?: boolean;
} & StatusFilter;

const columnsState: ColumnsStateType = {
  persistenceKey: "orderTable",
  persistenceType: "localStorage",
};

export default function OrderTable({
  statusFilter = {},
  rowClassName,
  pageSize = 10,
  order = "ASC",
  orderBy = "updatedDateRangeFrom",
  excludeEmail,
  includeEmail,
  hasAll = false,
  withPermission,
  onTotalDataChange,
  isAccountingTable,
}: {
  pageSize?: number;
  order?: SortingOrder;
  orderBy?: OrderByOptions;
  rowClassName?: string | ((record: InstallationSchema) => string);
  statusFilter?: StatusFilter;
  excludeEmail?: string | undefined;
  includeEmail?: string | undefined;
  hasAll?: boolean;
  withPermission?: boolean;
  onTotalDataChange?: (count: number | undefined) => void;
  isAccountingTable?: boolean;
}) {
  const [filterParams, setFilterParams] = useState<FilterParams>({
    page: 1,
    size: pageSize,
    search: "",
    order,
    orderBy,
    includeEmail,
    excludeEmail,
    hasAll,
    withPermission,
    isAccountingTable,
    ...statusFilter,
  });

  const [state, setState] = useState<{
    formModal: boolean;
    defaultValue: InstallationSchema | null;
  }>({
    formModal: false,
    defaultValue: null,
  });

  const { isLoading, data } = useInstallationList(filterParams);

  useEffect(() => {
    // Update defaulValue in model when data is invalidated
    setState((prev) => {
      const selectedInstallation = data?.items.find(
        (installation) => prev.defaultValue?.id === installation.id
      );

      if (!selectedInstallation) {
        return { ...prev };
      }

      return {
        ...prev,
        defaultValue: selectedInstallation,
      };
    });

    if (onTotalDataChange) {
      onTotalDataChange(data?.count);
    }
  }, [data?.items]);

  const { t } = useTranslation();

  if (isLoading) return <Spin />;

  const columns: ProColumns[] = [
    {
      key: "bookingNumber",
      title: t("orders:bookingNumber"),
      render: (_, row: InstallationSchema) => {
        const isWithPermission = getIsWithPermission(row);
        return (
          <div className="flex justify-between items-center">
            <div>{row.payment.paymentId}</div>
            {isWithPermission && <InvoiceStatusToggle installation={row} />}
          </div>
        );
      },
    },
    {
      key: "firstName",
      title: t("orders:firstName"),
      render: (_, row: InstallationSchema) => {
        return (
          <div>
            {row.additionalInformation?.additionalFirstName ||
              row.customer.firstName}
          </div>
        );
      },
    },
    {
      key: "lastName",
      title: t("orders:lastName"),
      render: (_, row: InstallationSchema) => {
        return (
          <div>
            {row.additionalInformation?.additionalLastName ||
              row.customer.lastName}
          </div>
        );
      },
    },
    {
      key: "companyName",
      title: t("orders:companyName"),
      render: (_, row: InstallationSchema) => {
        return (
          <div>
            {row.additionalInformation?.additionalCompanyName ||
              row.customer.companyName}
          </div>
        );
      },
    },
    {
      key: "blockingAddress",
      title: t("orders:blockingAddress"),
      render: (_, row: InstallationSchema) => {
        const zipCode =
          row.additionalInformation?.additionalZipCode || row.zipCode;
        return (
          <a
            href={getGoogleMapLink(row)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div style={{ width: 150 }}>
              {row.additionalInformation?.additionalStreetAndHouseNumber ||
                row.streetAndHouseNumber}
              , {zipCode ? `${zipCode} ` : ""}
              {row.additionalInformation?.additionalCity || row.city.name}
            </div>
          </a>
        );
      },
    },
    {
      key: "shutOffDate",
      title: t("orders:shutOffDate"),
      valueType: "option",
      render: (_, row: InstallationSchema) => {
        return (
          <div
            className="flex flex-col justify-center"
            style={{ width: 130, minHeight: 80 }}
          >
            {row.updatedDateRangeFrom === row.updatedDateRangeTo ? (
              <div>
                <b>{t("general:on")}: </b>
                {row.updatedDateRangeFrom}
              </div>
            ) : (
              <div className="flex flex-col gap-2">
                <div>
                  <b>{t("general:from")}: </b>
                  {row.updatedDateRangeFrom}
                </div>
                <div>
                  <b>{t("general:to")}: </b>
                  {row.updatedDateRangeTo}
                </div>
              </div>
            )}
          </div>
        );
      },
    },
    {
      key: "setupDate",
      title: t("orders:multiStatus.erectSigns"),
      render: (_, row: InstallationSchema) => {
        return <div style={{ width: 100 }}>{row.setupDate}</div>;
      },
    },
    {
      key: "pickupDate",
      title: t("orders:multiStatus.collectSigns"),
      render: (_, row: InstallationSchema) => {
        return <div style={{ width: 100 }}>{row.pickupDate}</div>;
      },
    },
    {
      key: "email",
      title: t("orders:email"),
      render: (_, row: InstallationSchema) => {
        return (
          <div>
            {row.additionalInformation?.additionalEmail || row.customer.email}
          </div>
        );
      },
    },
    {
      key: "noSignInstallation",
      title: t("orders:noSignInstallation"),
      render: (_, row: InstallationSchema) => (
        <NoSignInstallationCheckbox installation={row} />
      ),
    },
    {
      key: "status",
      title: t("orders:status"),
      render: (_, row: InstallationSchema) => (
        <StatusSelect installation={row} width={325} />
      ),
    },
    {
      key: "assignedTo",
      title: t("orders:assignedTo"),
      render: (_, row: InstallationSchema) => {
        return <AssignWorkerSelect row={row} />;
      },
    },
    {
      key: "workerOrderNumber",
      title: t("orders:workerOrderNumber"),
      render: (_, row: InstallationSchema) => {
        return <WorkerOrderNumber row={row} />;
      },
    },
    {
      key: "createdAt",
      title: t("orders:orderDate"),
      dataIndex: "createdAt",
      valueType: "date",
    },
    {
      key: "action",
      title: t("orders:action"),
      valueType: "option",
      render: (_, row: InstallationSchema, index) => [
        <div key={index}>
          <Button
            type="primary"
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                formModal: true,
                defaultValue: row,
              }))
            }
          >
            {t("main:view")}
          </Button>
        </div>,
      ],
    },
  ];

  const getSearchKeywordsDeb = debounce((value) => {
    setFilterParams({ ...filterParams, page: 1, search: value });
  }, 500);

  return (
    <React.Fragment>
      <AntdTablePro
        columns={columns}
        rowKey="id"
        dataSource={data?.items}
        rowClassName={rowClassName}
        columnsState={columnsState}
        className="order-table"
        pagination={
          hasAll
            ? false // No pagination if hasAll is true
            : {
                showSizeChanger: true,
                showQuickJumper: true,
                current: filterParams?.page,
                pageSize: filterParams?.size,
                total: data?.count,
                onChange: (page, pageSize) => {
                  setFilterParams({
                    ...filterParams,
                    page,
                    size: pageSize,
                  });
                },
              }
        }
        inputElement={{
          placeholder: t("main:search"),
          handleChange: (e) => getSearchKeywordsDeb(e.target.value),
          handleSearch: (value) =>
            setFilterParams({ ...filterParams, page: 1, search: value }),
        }}
      />
      {state.formModal && (
        <ShowInputProvider>
          <ViewComponent
            visible={state.formModal}
            defaultValue={state.defaultValue!}
            modalText={`${t("orders:viewOrders")} ${
              state.defaultValue?.payment.paymentId || ""
            }`}
            onHide={() =>
              setState((prevState) => ({ ...prevState, formModal: false }))
            }
          />
        </ShowInputProvider>
      )}
    </React.Fragment>
  );
}

function NoSignInstallationCheckbox({
  installation,
}: {
  installation: InstallationSchema;
}) {
  const { t } = useTranslation();
  const [optimisticCheck, setOptimisticCheck] = useState(
    installation.noSignInstallation
  );

  const { mutate, isLoading } = useInstallationUpdate(installation.id);

  const onChange = (v: boolean) => {
    setOptimisticCheck(v);
    mutate(
      { noSignInstallation: v },
      {
        onSuccess: () => {
          message.success(t("general:updatedSuccess"));
        },
        onError: () => {
          setOptimisticCheck((v) => !v);
          message.error(t("general:updateFail"));
        },
      }
    );
  };

  return (
    <div className="flex justify-center">
      <Checkbox
        style={{ transform: "scale(1.25)" }}
        checked={optimisticCheck}
        onChange={(e) => onChange(e.target.checked)}
        disabled={isLoading}
      />
    </div>
  );
}
