import { QuestionCircleOutlined } from "@ant-design/icons";
import type { ProColumns } from "@ant-design/pro-table";
import { Button, Popconfirm, Spin } from "antd";
import AntdTablePro from "components/antdTablePro";
import { debounce } from "lodash";
import {
  WorkerSchema,
  useDeleteWorker,
  useGetWorkerList,
} from "queryHook/worker";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BiPlus, BiTrash } from "react-icons/bi";
import { Link } from "react-router-dom";

type FilterParams = {
  page: number;
  size: number;
  search: string;
};

function Index() {
  const [filterParams, setFilterParams] = useState<FilterParams>({
    page: 1,
    size: 100,
    search: "",
  });

  const { isLoading, data } = useGetWorkerList(filterParams);

  const { t } = useTranslation();

  if (isLoading) return <Spin />;

  const columns: ProColumns[] = [
    {
      title: t("general:firstName"),
      dataIndex: "firstName",
    },
    {
      title: t("general:lastName"),
      dataIndex: "lastName",
    },
    {
      title: t("general:userName"),
      dataIndex: "userName",
    },
    {
      title: t("login:email"),
      dataIndex: "email",
    },
    {
      title: t("general:action"),
      render: (_, row: WorkerSchema, index) => [
        <DeleteButton worker={row} key={index} />,
      ],
    },
  ];

  const getSearchKeywordsDeb = debounce((value) => {
    setFilterParams((prev) => ({ ...prev, page: 1, search: value }));
  }, 500);

  return (
    <React.Fragment>
      <AntdTablePro
        columns={columns}
        rowKey="email"
        dataSource={data?.items}
        pagination={{
          showSizeChanger: true,
          showQuickJumper: true,
          current: filterParams?.page,
          pageSize: filterParams?.size,
          total: data?.count,
          onChange: (page, pageSize) => {
            setFilterParams({
              ...filterParams,
              page: page,
              size: pageSize,
            });
          },
        }}
        toolBarRender={[
          <Link
            key="addLink"
            to="/worker/add"
            className="ant-btn ant-btn-primary header-add-link"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span className="icon">
              <BiPlus />
            </span>
            <span className="text">{t("worker:addWorker")}</span>
          </Link>,
        ]}
        inputElement={{
          placeholder: t("main:search"),
          handleChange: (e) => getSearchKeywordsDeb(e.target.value),
        }}
      />
    </React.Fragment>
  );
}

export default Index;

function DeleteButton({ worker }: { worker: WorkerSchema }) {
  const { t } = useTranslation();
  const { mutate, isLoading } = useDeleteWorker(
    worker.userName || worker.email
  );

  return (
    <Popconfirm
      placement="leftBottom"
      icon={<QuestionCircleOutlined style={{ color: "red" }} />}
      title={t("general:confirmDelete")}
      onConfirm={() => mutate()}
      okText={t("general:yes")}
      cancelText={t("general:no")}
    >
      <Button type="primary" style={{ background: "red" }} loading={isLoading}>
        <BiTrash />
      </Button>
    </Popconfirm>
  );
}
